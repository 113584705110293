<template>
  <b-row>
    <b-col cols="12">
      <p class="primary fs-24 fw-bold text-center p-3 mb-0">
        Cambiar contraseña
      </p>
    </b-col>
    <b-col cols="12">
      <b-row class="justify-content-center pb-4">
        <b-col cols="12" sm="10" md="7" lg="6">
          <div class="px-3 px-md-4 px-lg-5 py-4 card-change-pass text-center">
            <FormCambiarClave />
          </div>
        </b-col>
      </b-row>
    </b-col>
    <ModalValidarOtp
      :tiempoReenvioCodigo="tiempoHabilitarReenvio"
      :digitosCelular="digitosCelular"
      @reenviarCodigo="reenviarCodigo"
      @sendForm="validarCodigo"
      @useModal="useModal"
      :modal="modalOtp"
      ref="modalOtp"
    />
  </b-row>
</template>

<script>
import ComunesService from "@/app/core/api/comunes";
import AlertsService from "@/app/shared/services/alerts.service";
import ModalValidarOtp from "@/app/shared/components/ModalValidarOtp.vue";
import FormCambiarClave from "@/app/shared/components/FormCambiarClave.vue";

export default {
  components: { FormCambiarClave, ModalValidarOtp },
  data() {
    return { digitosCelular: null, modalOtp: { isOpen: false }, tiempoHabilitarReenvio: null };
  },
  created() {
    this.generarCodigo();
  },
  methods: {
    useModal() {
      this.modalOtp.isOpen = false;
      this.$router.push("/home");
    },
    generarCodigo() {
      const body = { cedula: this.$store.getters.identificacion, idSolicitud: 0, estado: 0, tipo: "2" };

      ComunesService.generarCodigoCambiarClave(body)
        .then((response) => {
          if (response.data.estado) {
            this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
            this.digitosCelular = this.$store.getters.numeroCelular.slice(-4);
            setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
            this.modalOtp.isOpen = true;
            AlertsService.success("Cambio de contraseña", response.data.mensaje);
          }
        })
        .catch(() => {
          this.$router.push("/home");
        });
    },
    validarCodigo(codigo) {
      const body = { cedula: this.$store.getters.identificacion, codigoOTP: codigo };

      ComunesService.validarCodigoCambiarClave(body).then((response) => {
        AlertsService[response.data.estado ? "success" : "error"]("Validación Código OTP", response.data.mensaje);
        if (response.data.estado) {
          this.modalOtp.isOpen = false;
        }
        if (response.data.cerrarModal) this.$router.push("/home");
      });
    },
    reenviarCodigo() {
      const body = { cedula: this.$store.getters.identificacion, idSolicitud: 0, estado: 1, tipo: "1" };

      ComunesService.reenviarCodigoCambiarClave(body).then((response) => {
        if (response.data.estado) {
          this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
          setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
          AlertsService.success("Cambio de contraseña", response.data.mensaje);
        }
      });
    },
  },
};
</script>

<style>
.card-change-pass {
  border-radius: 1rem;
  background-color: #f5f9fd;
}
</style>
